import React from 'react'
import { createRoot } from 'react-dom/client'
import ApiBuilder from 'astra-core/config/ApiBuilder'
import CoreConfiguration from 'astra-core/config/CoreConfiguration'
import axios from 'axios'
import { configProviderActions } from 'astra-core/containers/ConfigProvider'

import { configureAppStore } from 'app/lib/store'
import { virtualGamesRoutes } from 'pages/model'
import { addLogoutInterceptor } from 'shared/lib/axios/interceptors'
import { injectLiveChat } from 'app/integrations/livechat'
import { injectSportradar } from 'app/integrations/sportradar'
import i18n from 'app/lib/i18n'
import { injectCyberbet } from 'app/integrations/cyberbet'
import { getAppConfig } from 'app/lib/get-config'

async function bootstrapApp() {
  console.debug('Starting app')
  const config = getAppConfig()
  console.debug(`Application version: ${config.APP_VERSION}`)
  const store = configureAppStore({})
  store.dispatch(configProviderActions.setConfig({ config }))

  const axiosInstance = axios.create({
    withCredentials: true
  })

  // @ts-ignore
  addLogoutInterceptor(axiosInstance, store, virtualGamesRoutes)

  CoreConfiguration.init({
    appConfig: config,
    apiBuilder: ApiBuilder.create(axiosInstance, config.API_URL)
  })

  const domNode = document.getElementById('root')
  const root = createRoot(domNode!)

  injectLiveChat(config.LIVECHAT_LICENCE)
  injectSportradar(config.BETRADAR_WIDGETS_URL, i18n.language)
  injectCyberbet(config.ODDIN_CLIENT_URL)

  import('./AppContainer').then(({ default: AppContainer }) => {
    root.render(<AppContainer store={store} />)
  })
}

bootstrapApp()

if (module.hot) {
  module.hot.accept()
}
