export enum ERoutes {
  About = '/profile/about',
  RefundPolicy = '/refund-policy',
  AMLPolicy = '/aml-policy',
  Documents = '/profile/documents',
  ResponsibleGambling = '/responsible-gambling',
  ProfileBalance = '/profile/balance',
  BetsHistory = '/profile/bets-history',
  BetGames = '/betgames',
  TurboGames = '/turbo-games',
  TwainGames = '/twain-games',
  TurboGamesGame = '/turbo-games/:gameNameId',
  SportGamesLobby = '/sport-games/sport-games-lobby',
  Calendar = '/calendar',
  FavouriteEvents = '/favourite',
  Identification = '/profile/identification',
  LiveRedirect = '/live',
  LiveEvents = '/live/:sportId',
  LiveEvent = '/live/:sportId/:eventId',
  LineRedirect = '/line',
  LineTournaments = '/line/:sportId',
  LineEvents = '/line/:sportId/:tournamentId',
  LineEvent = '/line/:sportId/:tournamentId/:eventId',
  LiveGames = '/livegames',
  MyBonuses = '/profile/my-bonuses',
  Notifications = '/profile/notifications',
  NewNotifications = '/profile/notifications/new',
  ReadNotifications = '/profile/notifications/read',
  TestCoin = '/profile/test-coin',
  Warning = '/profile/warning',
  Promotions = '/profile/promotions',
  PromotionsCurrent = '/profile/promotions/current',
  PromotionsPast = '/profile/promotions/past',
  BonusClub = '/profile/bonus-club',
  PrivacyPolicy = '/privacy-policy',
  Profile = '/profile/info',
  Results = '/results',
  Rules = '/rules',
  Root = '/',
  SuccessLoginPage = '/auth/success',
  SuccessFastSignUp = '/auth/fast-sign-up-success',
  SuccessFullSignUp = '/auth/full-sign-up-success',
  Statistics = '/statistics',
  LiveResults = '/live-results',
  Support = '/support',
  FAQ = '/faq',
  CategoryFAQ = '/faq/:categoryId',
  DetailsFAQ = '/faq/:categoryId/:faqId',
  TopUpMethod = '/profile/top-up/:paymentMethodId',
  Training = '/training',
  Partners = '/profile/partners',
  VirtualGames = '/virtual-games',
  TopUp = '/profile/balance/top-up',
  Withdraw = '/profile/balance/withdraw',
  BalanceHistory = '/profile/balance/history',
  WithdrawMethod = '/profile/withdraw/:withdrawMethodId',
  Won = '/profile/won',
  User = '/profile/user',
  HeadToHead = '/headToHead/:sourceEventId',
  Search = '/search',
  SearchTournaments = '/search/:tournamentId',
  NotFound = '/not-found',
  BetsGames = '/bets-games',
  UnderConstruction = '/under-construction',
  CyberSport = '/cybersport',
  BetsGamesGame = '/bets-games/game'
}
