import { Redirect } from 'react-router-dom'

import { PageAuthSuccessLazy } from 'pages/page-auth-success'
import { PageAuthSuccessSignUpLazy } from 'pages/page-auth-success-sign-up'
import { ERoutes, IRoute } from 'shared/types/routes'
import PageDocuments from 'pages/page-documents'

import {
  GamesCyberbetPage,
  MainPage,
  LivePage,
  LiveEventsPage,
  LiveEventPage,
  LinePage,
  LineTournamentsPage,
  LineEventPage,
  CalendarPage,
  FavoriteEventsPage,
  StatisticsPage,
  LiveResultsPage,
  ResultsPage,
  GamesBetPage,
  GamesTurboPage,
  GamesTwainPage,
  GamesTurboGamePage,
  SportGamesPage,
  LiveGamesPage,
  BalancePage,
  BetsHistoryPage,
  ProfilePage,
  BonusClubPage,
  NotificationPage,
  PromotionsPage,
  FAQPage,
  FAQDetailsPage,
  AboutPage,
  RefundPolicyPage,
  ResponsibleGamblingPage,
  PolicyPrivacyPage,
  PolicyAmlPage,
  SupportPage,
  WarningPage,
  HeadToHeadPage,
  SearchPage,
  SearchTournamentsPage,
  NotFoundPage,
  UnderConstructionPage,
  MyBonusesPage,
  PartnersPage
} from './imports'

// This is a static route configuration. It should include all of your top level
// routes, regardless of whether they are going to server render. In fact, you
// can totally point multiple routes to the same component! This is great for
// when you only need to server render a handful of routes and not your entire
// application!

export const routes: Array<IRoute> = [
  {
    path: ERoutes.Root,
    component: MainPage,
    name: 'Root',
    exact: true
  },
  {
    path: ERoutes.LiveRedirect,
    component: LivePage,
    name: 'Live',
    exact: true
  },
  {
    path: ERoutes.LiveEvents,
    component: LiveEventsPage,
    name: 'LiveEvents',
    exact: true
  },
  {
    path: ERoutes.LiveEvent,
    component: LiveEventPage,
    name: 'LiveEvent',
    exact: true
  },
  {
    path: ERoutes.LineEvents,
    component: LinePage,
    name: 'Line',
    exact: true
  },
  {
    path: ERoutes.LineTournaments,
    component: LineTournamentsPage,
    name: 'Line Tournament',
    exact: true
  },
  {
    path: ERoutes.LineEvent,
    component: LineEventPage,
    name: 'LineEvent',
    exact: true
  },
  {
    path: ERoutes.LineRedirect,
    render: () => <Redirect to="/line/100" />,
    name: 'Redirect to Line',
    exact: true
  },
  {
    path: ERoutes.Calendar,
    component: CalendarPage,
    name: 'Calendar',
    exact: true
  },
  {
    path: ERoutes.FavouriteEvents,
    component: FavoriteEventsPage,
    name: 'Favourite events',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Statistics,
    component: StatisticsPage,
    name: 'Statistics',
    exact: true
  },
  {
    path: ERoutes.LiveResults,
    component: LiveResultsPage,
    name: 'Live Results',
    exact: true
  },
  {
    path: ERoutes.Results,
    component: ResultsPage,
    name: 'Results',
    exact: true
  },
  {
    path: ERoutes.BetGames,
    component: GamesBetPage,
    name: 'BetGames TV',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TurboGames,
    component: GamesTurboPage,
    name: 'TurboGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TwainGames,
    component: GamesTwainPage,
    name: 'TwainGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TurboGamesGame,
    component: GamesTurboGamePage,
    name: 'TurboGamesGame',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.SportGamesLobby,
    component: SportGamesPage,
    name: 'SportGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.LiveGames,
    component: LiveGamesPage,
    name: 'Live Games',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.VirtualGames,
    name: 'Virtual Games',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.ProfileBalance,
    component: BalancePage,
    name: 'Balance',
    private: true
  },
  {
    path: ERoutes.BetsHistory,
    component: BetsHistoryPage,
    name: 'BetsHistory',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Profile,
    component: ProfilePage,
    name: 'Profile',
    exact: true,
    private: true
  },
  {
    path: ERoutes.BonusClub,
    component: BonusClubPage,
    name: 'Bonus club',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Identification,
    name: 'Identification',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Won,
    name: 'Won',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Notifications,
    component: NotificationPage,
    name: 'Notifications',
    private: true
  },
  {
    path: ERoutes.Promotions,
    component: PromotionsPage,
    name: 'Promotions'
  },
  {
    path: ERoutes.CategoryFAQ,
    component: FAQPage,
    name: 'Category FAQ',
    exact: true
  },
  {
    path: ERoutes.DetailsFAQ,
    component: FAQDetailsPage,
    name: 'Page FAQ',
    exact: true
  },
  {
    path: ERoutes.About,
    component: AboutPage,
    name: 'About',
    exact: true
  },
  {
    path: ERoutes.Documents,
    component: PageDocuments,
    name: 'Documents',
    exact: true
  },
  {
    path: ERoutes.RefundPolicy,
    component: RefundPolicyPage,
    name: 'Refund Policy',
    exact: true
  },
  {
    path: ERoutes.ResponsibleGambling,
    component: ResponsibleGamblingPage,
    name: 'Responsible Gambling',
    exact: true
  },
  {
    path: ERoutes.PrivacyPolicy,
    component: PolicyPrivacyPage,
    name: 'Privacy Policy',
    exact: true
  },
  {
    path: ERoutes.AMLPolicy,
    component: PolicyAmlPage,
    name: 'AML Policy',
    exact: true
  },
  {
    path: ERoutes.Support,
    component: SupportPage,
    name: 'Support',
    exact: true
  },
  {
    path: ERoutes.FAQ,
    component: () => <Redirect to={ERoutes.Support} />,
    name: 'FAQ',
    exact: true
  },
  {
    path: ERoutes.Warning,
    component: WarningPage,
    name: 'Warning',
    exact: true
  },
  {
    path: ERoutes.Training,
    name: 'Training',
    exact: true
  },
  // TODO: handle shit start
  {
    path: ERoutes.SuccessLoginPage,
    component: PageAuthSuccessLazy,
    name: 'Success Page',
    exact: true
  },
  {
    path: ERoutes.SuccessFastSignUp,
    component: PageAuthSuccessSignUpLazy,
    name: 'Success Fast Sign Up',
    exact: true
  },
  {
    path: ERoutes.SuccessFullSignUp,
    component: PageAuthSuccessSignUpLazy,
    name: 'Success Full Sign Up',
    exact: true
  },
  // TODO: handle this end
  {
    path: ERoutes.HeadToHead,
    component: HeadToHeadPage,
    name: 'Head 2 Head statistics Page',
    exact: true
  },
  {
    path: ERoutes.Search,
    component: SearchPage,
    name: 'Search Page',
    exact: true
  },
  {
    path: ERoutes.SearchTournaments,
    component: SearchTournamentsPage,
    name: 'Search Tournaments Page',
    exact: true
  },
  {
    path: ERoutes.NotFound,
    component: NotFoundPage,
    name: 'Not Found Page',
    exact: true
  },
  {
    path: ERoutes.BetsGames,
    component: SportGamesPage,
    name: 'BetsGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.UnderConstruction,
    component: UnderConstructionPage,
    name: 'Under Construction Page',
    exact: true
  },
  {
    path: ERoutes.CyberSport,
    component: GamesCyberbetPage,
    name: 'CyberSport',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.BetsGamesGame,
    component: UnderConstructionPage,
    name: 'BetsGamesGame',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.MyBonuses,
    component: MyBonusesPage,
    name: 'MyBonuses',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Partners,
    component: PartnersPage,
    name: 'Partners Page',
    exact: true
  }
]

export const virtualGamesRoutes = routes.reduce<Set<IRoute['path']>>(
  (acc, route) => {
    if (route.virtualGame) {
      acc.add(route.path)
    }
    return acc
  },
  new Set()
)
