import loadable from '@loadable/component'

import { fallback } from './fallback'

export const MainPage = loadable(() => import('../page-main'), {
  resolveComponent: (components) => components.PageMain,
  fallback
})

export const LivePage = loadable(() => import('../page-live'), {
  resolveComponent: (components) => components.PageLive,
  fallback
})

export const LiveEventsPage = loadable(() => import('../page-live-events'), {
  resolveComponent: (components) => components.PageLiveEvents,
  fallback
})

export const LiveEventPage = loadable(() => import('../page-live-event'), {
  resolveComponent: (components) => components.PageLiveEvent,
  fallback
})

export const LinePage = loadable(() => import('../page-line-events'), {
  resolveComponent: (components) => components.PageLine,
  fallback
})

export const LineTournamentsPage = loadable(
  () => import('../page-line-tournaments'),
  {
    resolveComponent: (components) => components.PageLineTournaments,
    fallback
  }
)

export const LineEventPage = loadable(() => import('../page-line-event'), {
  resolveComponent: (components) => components.PageLineEvent,
  fallback
})

export const CalendarPage = loadable(() => import('../page-calendar'), {
  resolveComponent: (components) => components.PageCalendar,
  fallback
})

export const FavoriteEventsPage = loadable(
  () => import('../page-favourite-events'),
  {
    resolveComponent: (components) => components.PageFavouriteEvents,
    fallback
  }
)

export const StatisticsPage = loadable(() => import('../page-statistics'), {
  resolveComponent: (components) => components.PageStatistics,
  fallback
})

export const LiveResultsPage = loadable(() => import('../page-live-results'), {
  resolveComponent: (components) => components.PageLiveResults,
  fallback
})

export const ResultsPage = loadable(() => import('../page-results'), {
  resolveComponent: (components) => components.PageResults,
  fallback
})

export const GamesBetPage = loadable(() => import('../page-games-bet'), {
  resolveComponent: (components) => components.PageGamesBet,
  fallback
})

export const GamesTurboPage = loadable(() => import('../page-games-turbo'), {
  resolveComponent: (components) => components.PageGamesTurbo,
  fallback
})

export const GamesTwainPage = loadable(() => import('../page-games-twain'), {
  resolveComponent: (components) => components.PageGamesTwain,
  fallback
})

export const GamesTurboGamePage = loadable(
  () => import('../page-games-turbo-game'),
  {
    resolveComponent: (components) => components.PageGamesTurboGame,
    fallback
  }
)

export const SportGamesPage = loadable(() => import('../page-sport-games'), {
  resolveComponent: (components) => components.PageSportGames,
  fallback
})

export const LiveGamesPage = loadable(() => import('../page-games-live'), {
  resolveComponent: (components) => components.PageGamesLive,
  fallback
})

export const BalancePage = loadable(() => import('../page-balance'), {
  resolveComponent: (components) => components.PageBalance,
  fallback
})

export const BetsHistoryPage = loadable(() => import('../page-bets-history'), {
  resolveComponent: (components) => components.PageBetsHistory,
  fallback
})

export const ProfilePage = loadable(() => import('../page-profile'), {
  resolveComponent: (components) => components.PageProfile,
  fallback
})

export const BonusClubPage = loadable(() => import('../bonus-club'), {
  resolveComponent: (components) => components.PageBonusClub,
  fallback
})

export const NotificationPage = loadable(() => import('../page-notification'), {
  resolveComponent: (components) => components.PageNotification,
  fallback
})

export const PromotionsPage = loadable(() => import('../page-promotions'), {
  resolveComponent: (components) => components.PagePromotions,
  fallback
})

export const FAQPage = loadable(() => import('../page-faq'), {
  resolveComponent: (components) => components.PageFAQ,
  fallback
})

export const FAQDetailsPage = loadable(() => import('../page-faq-details'), {
  resolveComponent: (components) => components.PageFAQDetails,
  fallback
})

export const AboutPage = loadable(() => import('../page-about'), {
  resolveComponent: (components) => components.PageAbout,
  fallback
})

export const DocumentsPage = loadable(() => import('../page-documents'), {
  resolveComponent: (components) => components.PageDocuments,
  fallback
})

export const RefundPolicyPage = loadable(
  () => import('../page-refund-policy'),
  {
    resolveComponent: (components) => components.PageRefundPolicy,
    fallback
  }
)

export const ResponsibleGamblingPage = loadable(
  () => import('../page-responsible-gambling'),
  {
    resolveComponent: (components) => components.PageResponsibleGambling,
    fallback
  }
)

export const PolicyPrivacyPage = loadable(
  () => import('../page-policy-privacy'),
  {
    resolveComponent: (components) => components.PagePolicyPrivacy,
    fallback
  }
)

export const PolicyAmlPage = loadable(() => import('../page-policy-aml'), {
  resolveComponent: (components) => components.PagePolicyAml,
  fallback
})

export const SupportPage = loadable(() => import('../page-support'), {
  resolveComponent: (components) => components.PageSupport,
  fallback
})

export const WarningPage = loadable(() => import('../page-warning'), {
  resolveComponent: (components) => components.PageWarning,
  fallback
})

export const HeadToHeadPage = loadable(() => import('../page-head-to-head'), {
  resolveComponent: (components) => components.PageHeadToHead,
  fallback
})

export const SearchPage = loadable(() => import('../page-search'), {
  resolveComponent: (components) => components.PageSearch,
  fallback
})

export const SearchTournamentsPage = loadable(
  () => import('../page-search-tournament'),
  {
    resolveComponent: (components) => components.PageSearchTournaments,
    fallback
  }
)

export const NotFoundPage = loadable(() => import('../page-not-found'), {
  resolveComponent: (components) => components.PageNotFound,
  fallback
})

export const UnderConstructionPage = loadable(
  () => import('../page-under-construction'),
  {
    resolveComponent: (components) => components.PageUnderConstruction,
    fallback
  }
)

export const GamesCyberbetPage = loadable(
  () => import('../page-games-cyberbet/ui'),
  {
    resolveComponent: (components) => components.PageGamesCyberbet,
    fallback
  }
)

export const MyBonusesPage = loadable(() => import('../page-my-bonuses'), {
  resolveComponent: (components) => components.PageMyBonuses,
  fallback
})

export const PartnersPage = loadable(() => import('../page-partners'), {
  resolveComponent: (components) => components.PagePartners,
  fallback
})
