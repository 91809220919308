import {
  EClientTypes,
  Config,
  EFeatureFlags
} from 'astra-core/containers/ConfigProvider'
import { CaptchaProviderType } from 'betweb-openapi-axios'

const configFromEnv = (): Config => {
  const FEATURE_FLAGS: Config['FEATURE_FLAGS'] = {
    [EFeatureFlags.RESPONSE_OF_EVENTS_ENABLED]: false,
    [EFeatureFlags.NEW_OUTCOMES_ENABLED]: false
  }

  // @ts-ignore
  return {
    API_URL: process.env.RAZZLE_API_URL || '',
    AUTH_REDIRECT_URL: process.env.RAZZLE_AUTH_REDIRECT_URL || '',
    CDN_URL: process.env.RAZZLE_CDN_URL || '',
    AUTH_SERVER_URL: process.env.RAZZLE_AUTH_SERVER_URL || '',
    BETGAMES_PARTNER_CODE: process.env.RAZZLE_BETGAMES_PARTNER_CODE || '',
    BETGAMES_SERVER: process.env.RAZZLE_BETGAMES_SERVER || '',
    TURBOGAMES_LAUNCH: process.env.RAZZLE_TURBOGAMES_LAUNCH || '',
    TURBOGAMES_LAUNCH_DEMO: process.env.RAZZLE_TURBOGAMES_LAUNCH_DEMO || '',
    TURBOGAMES_OPERATOR: process.env.RAZZLE_TURBOGAMES_OPERATOR || '',
    SPORTGAMES_PROVIDER_BASE_URL:
      process.env.RAZZLE_SPORTGAMES_PROVIDER_BASE_URL || '',
    BETGAMES_CLIENT_URL: process.env.RAZZLE_BETGAMES_CLIENT_URL || '',
    CURACAO_LICENCE_URL: process.env.RAZZLE_CURACAO_LICENCE_URL || '',
    BETRADAR_STATISTICS_URL: process.env.RAZZLE_BETRADAR_STATISTICS_URL || '',
    BETRADAR_LIVE_RESULTS_URL:
      process.env.RAZZLE_BETRADAR_LIVE_RESULTS_URL || '',
    BETRADAR_WIDGETS_URL: process.env.RAZZLE_BETRADAR_WIDGETS_URL || '',
    LIVECHAT_LICENCE: process.env.RAZZLE_LIVECHAT_LICENCE || '',
    LIVEGAMES_CLIENT_URL: process.env.RAZZLE_LIVEGAMES_CLIENT_URL || '',
    LIVEGAMES_PARTNER_CODE: process.env.RAZZLE_LIVEGAMES_PARTNER_CODE || '',
    CAPTCHA_KEY: process.env.RAZZLE_CAPTCHA_KEY || '',
    LOGO_URL: process.env.RAZZLE_LOGO_URL || '',
    LOGO_ICON_URL: process.env.RAZZLE_LOGO_ICON_URL || '',
    RULES_CDN_FOLDER: process.env.RAZZLE_RULES_CDN_FOLDER || '',
    CLIENT_RULES_URL: process.env.RAZZLE_CLIENT_RULES_URL || '',
    GAME_RISKS_URL: process.env.RAZZLE_GAME_RISKS_URL || '',
    TWAIN_GAMES_CLIENT_URL: process.env.RAZZLE_TWAIN_GAMES_CLIENT_URL || '',
    TWAIN_GAMES_API_URL: process.env.RAZZLE_TWAIN_GAMES_API_URL || '',
    TWAIN_GAMES_WS_URL: process.env.RAZZLE_TWAIN_GAMES_WS_URL || '',
    TWAIN_GAMES_PARTNER_CODE: process.env.RAZZLE_TWAIN_GAMES_PARTNER_CODE || '',
    ODDIN_CLIENT_URL: process.env.RAZZLE_ODDIN_CLIENT_URL || '',
    ODDIN_BRAND_TOKEN: process.env.RAZZLE_ODDIN_BRAND_TOKEN || '',
    IFRAME_AUTH_SERVER_URL: process.env.RAZZLE_IFRAME_AUTH_SERVER_URL || '',
    IS_IFRAME: process.env.RAZZLE_IS_IFRAME === 'true',
    IS_TRANSIT: process.env.RAZZLE_IS_TRANSIT === 'true',
    isDevelopment: true,
    FEATURE_FLAGS,
    MOBILE_WEB_APP_URL: '',
    CLIENT_TYPE: EClientTypes.WEB_DESKTOP, // To specify the current client type (for astra-core e.g.)
    YANDEX_METRIC_ID: 0, // no need in development
    HOTJAR_ID: 0, // no need in development
    DESKTOP_WEB_APP_URL: '',
    VSPORT_CLIENT_URL: '',
    VSPORT_CLIENT_ID: '',
    VSPORT_LOGOUT_ID: '',
    FRIGG_CLIENT_URL: '',
    BASKET_SUCCESS_SWITCH_DELAY: 4000,
    SENTRY_DSN: '',
    LANDING_APP_RU: '',
    LANDING_APP_EN: '',
    GOOGLE_METRIC_ID: '',
    ANDROID_APP_LINK: '',
    CAPTCHA_PROVIDER: CaptchaProviderType.Google,
    YANDEX_CAPTCHA_KEY: '',
    HUAWEI_CAPTCHA_KEY: '',
    GOOGLE_CAPTCHA_KEY: ''
  }
}

const isDevelopment = process.env.NODE_ENV === 'development'

export function getAppConfig(): Config {
  if (isDevelopment) {
    return { ...configFromEnv(), API_URL: '/api' }
  }
  try {
    return window.config
  } catch (e) {
    throw Error('Couldnt get config')
  }
}
