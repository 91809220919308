/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'
import {
  sliceKey as configProviderSliceKey,
  reducer as configProviderReducer
} from 'astra-core/containers/ConfigProvider/slice'

import { baseLegacyApi, basePromoAPi } from 'entities/legacy-api'
import { InjectedReducersType } from 'shared/lib/@reduxjs'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  const rootReducer = combineReducers({
    [baseLegacyApi.reducerPath]: baseLegacyApi.reducer,
    [basePromoAPi.reducerPath]: basePromoAPi.reducer,
    [configProviderSliceKey]: configProviderReducer,
    ...injectedReducers
    // other non-injected reducers can go here...
  })

  return rootReducer
}
